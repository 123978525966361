



































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import { waitTimer } from '@/core/pendings'
import { RouteNames } from '@/router'
import { settingsModule } from '@/store/settings'
import { BButton, BSpinner } from 'bootstrap-vue'
import { round } from 'lodash-es'
import { logBreadcrumb } from '@/core/logger'
import { CartStatus, Scalars } from '@/graphql/default/graphql.schema'
import { ErrorTypes, Provider } from '@/graphql/terminal/graphql.schema'
import { cart } from '@/store/cart/cartModule'
import { CartError, ServerErrors } from '@/store/cart/serverErrors'
import Sentry from '@/plugins/sentry'
import { Severity } from '@sentry/types'

const ShowPaymentReceipt = () => import('@/components/paymentStep/ShowPaymentReceipt.vue')
const WaitingForTerminal = () => import('@/components/paymentStep/WaitingForTerminal.vue')
const CardPaymentError = () => import('@/components/paymentStep/CardPaymentError.vue')
const FoodcardWait = () => import('@/components/paymentStep/FoodcardWait.vue')
const YandexWait = () => import('@/components/paymentStep/YandexWait.vue')
const NalunchWait = () => import('@/components/paymentStep/NalunchWait.vue')
const UnknownPaymentError = () => import('@/components/paymentStep/PaymentError.vue')
const FoodcardReadError = () => import('@/components/paymentStep/FoodcardReadError.vue')
const ErrorNoMoney = () => import('@/components/paymentStep/ErrorNoMoney.vue')

const TAG = 'Payment.vue'

const log = (...arg: any[]) =>
  logBreadcrumb({ tag: TAG, color: 'green' }, ...arg)

@Component({
  components: {
    ErrorNoMoney,
    YandexWait,
    NalunchWait,
    FoodcardReadError,
    UnknownPaymentError,
    FoodcardWait,
    CardPaymentError,
    WaitingForTerminal,
    ShowPaymentReceipt,
    QrcodeVue,
    BButton,
    BSpinner
  },
  filters: {
    round
  }
})
export default class Payment extends Vue {
  readonly RouteNames = RouteNames;
  readonly CartStatus = CartStatus;
  readonly ErrorTypes = ErrorTypes;
  readonly Provider = Provider;
  private paymentError: any | ServerErrors = null;
  private cartID: string | null = null;

  @Watch('paymentError')
  handlerPaymentError() {
    if (this.paymentError) {
      this.blockPage = false
    }
  }

  get errorType(): ErrorTypes | undefined {
    if (this.paymentError instanceof ServerErrors) {
      return this.paymentError.type
    }
    return undefined
  }

  get userMessageError(): undefined | string {
    return this.paymentError.message
  }

  @Prop({ type: String, default: Provider.Sber })
  provider!: Provider;

  blockPage = false;

  timer = 0;

  receiptUrl: string | null = null;

  nalunchQrCode: string | null = null;

  @Watch('blockPage', { immediate: true })
  watchBlockPage(blockPage: boolean) {
    if (blockPage) {
      window.document.body.classList.add('block-page-for-payment')
    } else {
      window.document.body.classList.remove('block-page-for-payment')
    }
  }

  @Watch('provider', { immediate: false })
  restartPayment() {
    this.payCart()
  }

  created() {
    this.payCart()
  }

  // процесс оплаты
  async payCart() {
    log('процесс оплаты')
    this.blockPage = true
    this.paymentError = null
    const provider = this.provider
    try {
      if (provider === Provider.Nalunch) {
        this.nalunchQrCode = await cart.getQrCodeForNalunch()
      }
      this.cartID = await cart.payCart(provider)
    } catch (error) {
      log('Обрабатываем ошибку оплаты', error)
      if (provider === this.provider) {
        this.blockPage = false
        this.paymentError = error
        const step = 1e3
        this.timer =
          settingsModule.state.publicSettings.paymentErrorDisplayTime
        waitTimer(
          settingsModule.state.publicSettings.paymentErrorDisplayTime,
          step,
          () => {
            this.timer -= step
            return (
              !this.paymentError || this.$route.name !== RouteNames.payment
            )
          }
        ).catch(() => {
          // не дождались, уходим на главную
          this.$router.push({
            name: RouteNames.index
          })
        })
      }
      throw error
    }
    this.waitReceipt(this.cartID)
  }

  // процесс ожидания чека
  async waitReceipt(cartID: Scalars['ID']) {
    log('процесс ожидания чека')
    this.receiptUrl =
      'mailto:info@workeat.com?subject=Запрос чека&body=' +
      `Хочу получить чек для корзины ${atob(cartID).replace('CartType:', '')}`
    this.blockPage = false
    // таймер ожидания receiptUrl
    this.timer = settingsModule.state.publicSettings.waitingTimeForReceipt
    waitTimer(
      settingsModule.state.publicSettings.waitingTimeForReceipt,
      1e3,
      () => {
        this.timer -= 1e3
        return this.$route.name !== RouteNames.payment || !!this.receiptUrl
      }
    ).catch(() => {
      this.$router.push({
        name: RouteNames.index
      })
    })

    try {
      const receiptUrl = await cart.getReceipt(cartID)

      // таймер отображения receiptUrl
      this.timer = settingsModule.state.publicSettings.receiptDisplayTime
      waitTimer(
        settingsModule.state.publicSettings.receiptDisplayTime,
        1e3,
        () => {
          this.timer -= 1e3
          return this.$route.name !== RouteNames.payment
        }
      ).catch(() => {
        this.$router.push({
          name: RouteNames.index
        })
      })

      this.receiptUrl = receiptUrl
    } catch (e) {
      if (e instanceof CartError) {
        Sentry.captureMessage(e.message, {
          level: Severity.Warning,
          extra: {
            cart: e.cart
          }
        })
      } else {
        throw e
      }
    }
  }

  beforeDestroy() {
    cart.reset(this.cartID ?? undefined)
    window.document.body.classList.remove('block-page-for-payment')
  }

  // способ оплыты не банковской картой
  get isNoBankProvider(): boolean {
    return this.provider === Provider.Nalunch ||
      this.provider === Provider.Yandex ||
      this.provider === Provider.Foodcard
  }

  // ожидание чека foodcard
  get isFoodcardWait(): boolean {
    return (
      !this.paymentError &&
      this.provider === Provider.Foodcard &&
      !this.receiptUrl
    )
  }

  get isYandexWait(): boolean {
    return (
      !this.paymentError &&
      this.provider === Provider.Yandex &&
      !this.receiptUrl
    )
  }

  get isYandexBadgeWait(): boolean {
    return (
      !this.paymentError &&
      this.provider === Provider.YandexBadge &&
      !this.receiptUrl
    )
  }

  // ожидание оплаты через Наланч
  get isNalunchWait(): boolean {
    return (
      !this.paymentError &&
      this.provider === Provider.Nalunch &&
      !this.receiptUrl
    )
  }

  // ошибка чтения foodcard
  get isFoodcardReadError(): boolean {
    return (
      !this.paymentError &&
      this.provider === Provider.Foodcard &&
      (this.errorType === ErrorTypes.ReadScannerError ||
        this.errorType === ErrorTypes.ScannerTimeoutError ||
        this.errorType === ErrorTypes.ValidateCardError)
    )
  }

  // недостаточно средств
  get isNoMoney(): boolean {
    return (
      !this.paymentError &&
      this.isNoBankProvider &&
      this.errorType === ErrorTypes.InsufficientFundsError
    )
  }

  // ошибка оплаты не банковской картой
  get isNoBankError(): boolean {
    return this.isNoBankProvider && this.paymentError
  }

  // ожидание терминала
  get isWaitingForTerminal(): boolean {
    return !this.paymentError && !this.receiptUrl
  }

  // отображение чека
  get isShowPaymentReceipt(): boolean {
    return !this.paymentError && !!this.receiptUrl
  }
}
